.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFViewer canvas {
  @apply shadow
}

.react-pdf__Document .react-pdf__Page .react-pdf__Page__textContent {
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
}

.react-pdf__Document .react-pdf__Page .react-pdf__Page__annotations {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
}

.react-pdf__Document .react-pdf__Page .react-pdf__Page__annotation section {
  position: absolute;
  top: 0px;
  left: 0px;
}
.react-pdf__Document
  .react-pdf__Page
  .react-pdf__Page__annotation
  section
  section
  > a {
  display: block;
  width: 100%;
  height: 100%;
}
